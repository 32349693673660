<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M15 30C10.9934 30 7.22649 28.4397 4.39339 25.6066C1.56029 22.7735 0 19.0066 0 15C0 10.9934 1.56029 7.22649 4.39339 4.39339C7.22649 1.56029 10.9934 0 15 0C19.0066 0 22.7735 1.56029 25.6066 4.39339C28.4397 7.22649 30 10.9934 30 15C30 17.9608 29.1211 20.8404 27.4585 23.3272C27.0989 23.8651 26.3711 24.0097 25.833 23.6499C25.2949 23.2903 25.1505 22.5625 25.51 22.0244C26.9142 19.9244 27.6562 17.4955 27.6562 15C27.6562 8.02139 21.9786 2.34375 15 2.34375C8.02139 2.34375 2.34375 8.02139 2.34375 15C2.34375 21.9786 8.02139 27.6562 15 27.6562C17.3149 27.6562 19.5788 27.0259 21.5472 25.8337C22.1006 25.4984 22.8211 25.6753 23.1564 26.2287C23.492 26.7824 23.315 27.5029 22.7614 27.8382C20.4268 29.2525 17.7429 30 15 30ZM9.26331 20.343L12.4118 17.2158C13.0229 16.6086 13.3594 15.8011 13.3594 14.9414C13.3594 14.0817 13.0229 13.2742 12.4116 12.667L9.26331 9.53979C8.80417 9.08363 8.06236 9.08592 7.6062 9.54529C7.15004 10.0044 7.15256 10.7465 7.61169 11.2026L10.76 14.3298C10.9248 14.4937 11.0156 14.7107 11.0156 14.9414C11.0156 15.1721 10.9248 15.3891 10.76 15.553L7.61169 18.6804C7.15256 19.1364 7.15004 19.8784 7.6062 20.3375C7.83508 20.5682 8.13629 20.6836 8.4375 20.6836C8.73619 20.6836 9.03488 20.5701 9.26331 20.343ZM22.5055 18.739C22.9646 19.1949 22.9671 19.937 22.511 20.3961C22.2821 20.6268 21.9809 20.7422 21.6797 20.7422C21.381 20.7422 21.0825 20.6287 20.8539 20.4016L17.7056 17.2744C17.0943 16.6672 16.7578 15.8597 16.7578 15C16.7578 14.1403 17.0943 13.3328 17.7054 12.7256L20.8539 9.59816C21.313 9.142 22.0548 9.14452 22.511 9.60388C22.9671 10.063 22.9646 10.8051 22.5055 11.261L19.3572 14.3884C19.1924 14.5523 19.1016 14.7693 19.1016 15C19.1016 15.2307 19.1924 15.4477 19.3572 15.6116L22.5055 18.739Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
  export default {
    name: 'CloseModal'
  }
</script>
